.menuContainer {
  margin-top: 80px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  align-items: center;
}

.btalkTxt {
  color: #f4b83c;
  font-size: 25px;
  font-weight: bold;
}

.menuItemsContainer {
  width: 50%;
  margin: auto;
  margin-top: 20px;
}

.menuItem {
  margin-bottom: 10px;
  border-radius: 20px;
  padding-left: 20px;
  cursor: pointer;
}

.menuItemBtn {
  background-color: inherit;
  border: 0;
  display: flex;
  align-items: center;
  height: 70px;
  cursor: pointer;
  width: 100%;
}

.menuItemIconContainer {
  margin-right: 20px;
}

.menuItem:hover {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.menuItemTxt {
  color: #f4b83c;
}

.userImg {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  border: 1px #f4b83c solid;
}

.blueBidgeimg {
  width: 25px;
  margin-left: 5px;
}
