.loginFirstContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  align-items: center;
}

.languageBtn {
  background-color: inherit;
  border-width: 0;
  cursor: pointer;
}

.languageTxt {
  font-weight: bold;
}

.theme-button {
  padding: 10px 20px; /* Adjust padding to control the spacing inside the button */
  border: none;
  background-color: inherit;
  color: #333;
  cursor: pointer;
  font-family: Arial, sans-serif;
  position: relative;
  display: inline-block;
  width: 150px;
  height: 100px;
  text-align: center;
  line-height: 50px;
  overflow: hidden;
}

.theme-button::before {
  content: '🌒';
  font-size: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s, opacity 0.3s;
}

.theme-button::after {
  content: '☀️'; /* Moon icon */
  font-size: 50px;
  position: absolute;
  right: 20%;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s, opacity 0.3s;
}

.dark-theme .theme-button::before {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.1);
}

.dark-theme .theme-button::after {
  transform: translateY(-50%) scale(1);
  opacity: 1;
  display: inline-block;
  height: 50;
  width: 100;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 150px;
  height: auto;
}

.inputContainer {
  width: 50%;
  margin: auto;
  margin-top: 50px;
}

.inputItemsContainer {
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.inputItem {
  width: 100%;
  border: 0;
  height: 50px;
  padding-left: 20px;
  border: #333 1px solid;
  border-radius: 20px;
}

.showPassBtn {
  position: absolute;
  right: 25%;
  border: 0;
  cursor: pointer;
  background-color: inherit;
  margin-top: -40px;
}

.fupContainer {
  margin-top: -10px;
}

.fupBtn {
  border: 0;
  background-color: inherit;
  cursor: pointer;
}

.fuptxt {
  color: blue;
}

.fuptxt:hover {
  color: lightblue;
}

.btnContainer {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginbtn {
  background-color: #f4b83c;
  color: white;
  border: 0;
  border-radius: 20px;
  width: 300px;
  height: 50px;
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
}

.loginbtn:hover {
  background-color: #ffde2e;
}

.nyrtxt {
  color: #555;
  margin-top: 50px;
}

.cnabtn {
  background-color: inherit;
  border-radius: 20px;
  width: 300px;
  height: 50px;
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
  border-width: 1px;
}

.cnabtn:hover {
  background-color: #f4b83c;
  border: 0;
}

.ortxt {
  color: #555;
  margin-top: 10px;
}

.footerContainer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
