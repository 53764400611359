.forgotPasswordFirstContainer,
.forgotPasswordSecondContainer,
.forgotPasswordThirdContainer,
.forgotPasswordForthContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.searchheadertxt {
  font-size: larger;
  font-weight: bold;
  width: 50%;
}

.emailIpnut {
  width: 50%;
  height: 50px;
  border-radius: 20px;
  padding-left: 10px;
}

.btn {
  width: 50%;
  color: #fff;
  background-color: #00569b;
  border: 0px;
  height: 50px;
  border-radius: 20px;
  font-weight: bold;
  font-size: large;
  cursor: pointer;
}

.btn:hover {
  background-color: #f4b83c;
}

.goBackContainer {
  border: 0;
  background-color: inherit;
  cursor: pointer;
}

.userPPimg {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 1px #f4b83c solid;
  margin-right: 10px;
}

.accPPNameContainer {
  display: flex;
  align-items: center;
}

.BlueVerifyBadge {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}

.userName {
  font-size: larger;
  font-weight: bold;
  color: #fff;
}

.accPPNameContainer {
  width: 50%;
  border-radius: 20px;
  justify-content: center;
  margin-bottom: 30px;
  background-color: #00bcd4;
}

.passContaienr {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.showPassIconBtn {
  cursor: pointer;
  border: 0;
  background-color: inherit;
  margin-left: -45px;
}

.goBackContainer {
  margin-top: 30px;
  margin-left: 20%;
}

.gotoLogin {
  border: 0;
  background-color: inherit;
  cursor: pointer;
  display: flex;
  margin: auto;
  margin-top: 100px;
  color: #00569b;
  font-size: large;
  font-weight: bold;
}

.gotoLogin:hover {
  color: #00bcd4;
}
