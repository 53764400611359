.singleMagazineImage {
  background-color: inherit;
  border-width: 0;
  text-align: center;
  transition: background-color 0.3s;
  cursor: pointer;
}

.singleMagazineImage:hover {
  background-color: #fff;
}

.seemoremagazinebtn {
  position: absolute;
  top: 3px;
  height: 390px;
  width: 250px;
  left: 9px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}

.seemoremagazinebtn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
