.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-container {
  background-color: #fff;
  padding: 20px;
  width: 30%; /* Responsive width */
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.modal-header {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.modal-title {
  margin-top: 0;
  color: #333;
  text-align: center;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: #aaa;
  cursor: pointer;
}

.modal-close:hover {
  color: #777;
}

.modal-body {
  padding: 10px 0;
}

.modal-footer {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

.action {
  display: flex;
  justify-content: space-around;
}

.modal-button {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-button:hover {
  background-color: #0056b3;
}
