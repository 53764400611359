.monthlynav {
  position: fixed;
  width: 100%;
  top: 0;
  transition: top 0.3s;
}

.magazineTitle {
  font-size: x-large;
  font-weight: bolder;
}

.imgs {
  width: 100%;
}

.imgs:hover {
  background-color: #fc3c44;
  padding: 3px;
}

.childsContainer {
  width: 40%;
  margin: auto;
}
.matikarItem {
  border: 0;
  background-color: inherit;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin: auto;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 10px;
}

.matikarItem:hover {
  color: #00bcd4;
}

.storyHeader {
  font-size: 18px;
  font-weight: bold;
  color: #fc3c44;
  text-align: center;
  width: 100%;
  align-self: center;
  border-width: 3px;
  border-color: #fc3c44;
}

.underlineDiv {
  height: 5px;
  background-color: #000;
  width: 100%;
  align-self: center;
  margin-bottom: 20px;
}

.storyContainer {
  margin-bottom: 50px;
  width: 100%;
}

.mtkContainer {
  width: 100%;
}

@media screen and (max-width: 1250px) {
  .magazineTitle {
    font-size: large;
    font-weight: bold;
  }

  .childsContainer {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .magazineTitle {
    font-size: large;
    font-weight: bold;
  }

  .childsContainer {
    width: 100%;
  }
}
