.regFirstBtn {
  background-color: #00bcd4;
  border: 0;
  border-radius: 20px;
  width: 40%;
  height: 50px;
  font-weight: bold;
  font-size: large;
  cursor: pointer;
  color: #222;
  margin: auto;
  margin-top: 20px;
}

.regFirstBtn:hover {
  background-color: #1c545c;
}

.regFirstContainer,
.regSecondContainer,
.regThirdContainer,
.regForthContainer,
.regFifthContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
}

.joinBTalkTxt {
  font-size: larger;
  font-weight: bold;
}
.input {
  height: 50px;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: large;
  color: #00bcd4;
}

.label {
  margin-right: 20px;
  font-size: large;
  color: #00bcd4;
  margin-bottom: 20px;
  display: inline-block;
  cursor: pointer;
}

.radio {
  cursor: pointer;
}

.password {
  width: 100%;
}

.passshow {
  border: 0;
  cursor: pointer;
  background-color: inherit;
  position: absolute;
  right: 26%;
}

.policybtn {
  border: 0;
  background-color: inherit;
  color: blue;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
}

.policybtn:hover {
  color: #00bcd4;
}
