.seemorebtn {
  font-size: larger;
  font-weight: bolder;
  border: 0;
  background-color: #00569b;
  color: #fff;
  height: 50px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 20px;
}

.seemorebtn:hover {
  background-color: #00bcd4;
}
