.flexCenter {
  display: flex;
  width: 100;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.monthlynav {
  position: fixed;
  width: 100%;
  top: 0;
  transition: top 0.3s;
}

.magazineTitle {
  font-size: larger;
  font-weight: bold;
}

.darkContainerBGC {
  background-color: #111;
}

.lightContainerBGC {
  background-color: #f0f0f0;
}

.container {
  align-items: center;
  width: 50%;
  border-radius: 12px;
  padding-bottom: 10px;
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 3px;
  margin-bottom: 50px;
  background-color: #f0f0f0;
}

.iconShow {
  display: flex;
}

.iconHide {
  display: none;
}

.menu {
  position: absolute;
  right: 0;
  border-color: gainsboro;
  border-width: 1px;
  border-radius: 8px;
  padding: 5px 20px;
  z-index: 1;
}

.text1 {
  font-size: 18px;
  font-weight: bold;
  padding: 5px 0;
}

.darkTextColor {
  color: #fff;
}

.lightTextColor {
  color: #333;
}

.posterName {
  font-weight: bold;
  font-size: 18px;
}

.backArrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.bottomConatiner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-color: gainsboro;
  border-bottom-width: 1px;
  padding-bottom: 5px;
  margin-top: 10px;
  width: 90%;
}

image {
  resize: cover;
  min-height: 500px;
}

.commentView {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.modalView {
  border-radius: 20px;
  padding: 10;
  display: flex;
  align-items: center;
  width: 50%;
}

.modalDarkViewBGC {
  background-color: #000;
}

.modalLightViewBGC {
  background-color: #fff;
}

.commentContainer {
  min-height: 200px;
  width: 90%;
  margin-top: 10px;
}

.cmDataContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.commenterPP {
  border-radius: 100px;
  margin-right: 10px;
  margin-left: -5px;
  border-color: #ffde2e;
  border-width: 1px;
  width: 50px;
  height: 50px;
}

.commenterPPIcon {
  padding: 3px 5px;
  border-radius: 100px;
  margin-right: 10px;
  border-color: #ffde2e;
  border-width: 1px;
}

.cmItemContainer {
  margin-left: 5px;
  border-color: gainsboro;
  border-width: 1px;
  border-radius: 15px;
  padding: 5px 15px;
}

.cmData {
  margin-top: 5px;
  max-width: 90%;
}

.gridContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-self: center;
  max-height: 100vh;
  overflow: hidden;
}

.image1 {
  height: 100%;
  width: 100%;
}

.overlayContainer {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.overlayText {
  color: #f0f0f0;
  font-size: 20px;
  font-weight: bold;
}

.likebtn {
  cursor: pointer;
  border: 0;
  background-color: inherit;
}

.likebtn:hover {
  background-color: #00bcd4;
}

.btn1:hover {
  background-color: inherit;
}

@media screen and (max-width: 1250px) {
  .container,
  .modalView {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .container,
  .modalView {
    width: 90%;
  }
  .magazineTitle {
    font-size: large;
  }
}
